import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import WhatIsBCST from '../components/WhatIsBCST';

function AboutBCST() {
  return (
    <Layout>
      <SEO
        keywords={[`Wellspring BCST`, `Wellspring Cranio`, `Wellspring Biodynamic Craniosacral Therapy`, `What is BCST?`, `What is Biodynamic Craniosacral Therapy?`]}
        description="Biodynamic Craniosacral Therapy (BCST) is a gentle, hands-on approach that releases tensions deep in the body to relieve pain and dysfunction and improve whole-body health and performance. BCST combines a scientific understanding of the body with mindfulness skills."
        title="What is BCST?"
      />

      <WhatIsBCST />
    </Layout>
  );
}

export default AboutBCST;
