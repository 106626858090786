import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

// import BookNow from './BookNow';

function WhatIsBCST() {
  const { aurora, whatIsBcst, whatIsBcst2 } = useStaticQuery(graphql`
    query {
      aurora: file(relativePath: { eq: "aurora.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800 ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      whatIsBcst: file(relativePath: { eq: "what-is-bcst.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800 ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      whatIsBcst2: file(relativePath: { eq: "what-is-bcst-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800 ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className="px-8 md:px-16 lg:max-w-screen-lg mx-auto">
      <h1 className="my-8 md:my-12 lg:my-16 font-semibold text-lg md:text-2xl lg:text-3xl text-gray-800 tracking-wider text-center">
        What is Biodynamic Craniosacral Therapy (BCST)?
      </h1>

      <div className="my-12">
        <Img
          className="my-6 md:my-12 mx-auto"
          style={{ maxWidth: "800px" }}
          fluid={aurora.childImageSharp.fluid}
          alt="Aurora Borealis"
        />

        <div className="mx-6 md:mx-24 my-12">
          <blockquote className="pl-4 font-serif leading-loose text-justify border-l-4 border-gray-900">
            Every hidden cell is throbbing with music and life.
          </blockquote>
          <cite className="block mt-4 text-xs font-bold text-right uppercase">
            - John Muir
          </cite>
        </div>

        <p className="mt-8">
          BCST is a gentle, hands on therapy that relieves tension within the structures and tissues surrounding the central nervous system promoting whole body health and proper function. Many symptoms that bring people to craniosacral therapy are related to difficulties in <span className="font-semibold">nervous system regulation.</span> The primary benefit of BCST is supporting this physiological balance.
        </p>

        <p className="mt-6">
          Everyday our body endures stressors that it constantly seeks to balance out; however sometimes our tissues tighten under the strain or our physiology gets stuck in it’s stress response. Sometimes our body will even hold patterns of tension from previous experiences as far back in our history as birth! BCST helps to ease the environment around the central nervous system, supporting its natural rhythm and ability for self repair.
        </p>

        <Img
          className="my-6 md:my-12 mx-auto"
          style={{ maxWidth: "800px" }}
          fluid={whatIsBcst.childImageSharp.fluid}
          alt="BCST appointment"
        />

        <p className="mt-6">
          Biodynamic means LIFE-MOVEMENT. In the Biodynamic approach to CST we are interested in how the organizing forces of Life are expressed in the tissues, fluids and energetic fields of the body. These subtle movements can be palpated by trained, sensitive hands that deeply listen to the whole of the being they are in contact with. This unique type of touch is light and reverential, without any force or manipulation. Instead touch is combined with the calm, embodied presence of the therapist. This combination creates a sense of safety that allows a body to express the patterns and experiences it is holding and find resolution. By helping clients reestablish their own balanced rhythm, the regenerative powers of the body are able to make intrinsic internal adjustments. Healing comes from within the client, rather than from an outside source!
        </p>

        <p className="mt-6">
          BCST is a gentle therapy appropriate for a wide variety of clients including pre- and postnatal women, newborns, young children, adults and the elderly. Clients seek treatment for many different symptoms including pain, fatigue, stress and related difficulties. Clients are also interested in improving their overall physical, mental and emotional wellbeing.
        </p>

        <Img
          className="my-6 md:my-12 mx-auto"
          style={{ maxWidth: "800px" }}
          fluid={whatIsBcst2.childImageSharp.fluid}
          alt="BCST appointment"
        />
      </div>

      {/* <BookNow /> */}

      <div className="my-12">
        <h4 className="">
          Additional Resources
        </h4>

        <ul className="mt-4 mb-16">
          <li className="">
            <OutboundLink className="ml-3 no-underline hover:underline text-blue-500" href="https://www.bodyintelligence.com/" target="_blank" rel="noopener noreferrer"> Body Intelligence</OutboundLink>
          </li>
          <li className="">
            <OutboundLink className="ml-3 no-underline hover:underline text-blue-500" href="https://www.craniosacraltherapy.org/" target="_blank" rel="noopener noreferrer">The Biodynamic Craniosacral Therapy Association of NA</OutboundLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default WhatIsBCST;
